import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactMemo } from 'src/hooks';
import PageContainer from 'src/components/PageContainer';
import { Form, FormControls } from 'src/components/StyledCommon';
import BackButton from 'src/components/BackButton';

import {PASS_MIN_LENGTH, emailValidation, alphaAndDigitValidation, gtmSend, getLocalLanguage} from 'src/utils';

import store from 'src/store';
import api, { ApiErrorDescriptions } from 'src/services/api';

import { useTranslation } from 'src/i18n/useTranslation';
import i18n from 'src/i18n/i18n';
import { Button, Input, InputContainer, TInputMode } from 'src/libs';
import ReCAPTCHA from "react-google-recaptcha";

export default ReactMemo(function SignUp() {

  const navigate = useNavigate();

  const [state, setState] = React.useState<{
    channel: string;
    password: string;
    channelMode: TInputMode;
    channelMessage: string;
    passwordMode: TInputMode;
    passwordMessage: string;
  }>({
    channel: '',
    password: '',
    channelMode: 'normal',
    channelMessage: '',
    passwordMode: 'normal',
    passwordMessage: ''
  });

  const captchaRef = React.useRef<ReCAPTCHA>(null);

  const { t, U } = useTranslation();
  const isFormValid = React.useMemo(() => {
    const validChannel = state.channel && emailValidation(state.channel);
    const validPassword = state.password && state.password.length >= PASS_MIN_LENGTH && alphaAndDigitValidation(state.password);
    return Boolean(validChannel && validPassword);
  }, [state.channel, state.password]);

  // валидация
  React.useEffect(() => {
    if (state.channel && !emailValidation(state.channel)) {
      setState(state => ({
        ...state,
        channelMode: 'error',
        channelMessage: t("chronos.auth.signUp.wrongFormat")
      }));
    }
    if (state.password) {
      const errFormat = !alphaAndDigitValidation(state.password);
      const errLength = state.password.length < PASS_MIN_LENGTH;
      setState(state => ({
        ...state,
        passwordMode: errFormat || errLength ? 'error' : 'normal',
        passwordMessage: errLength ? t("chronos.auth.signUp.minimumLength", { PASS_MIN_LENGTH: PASS_MIN_LENGTH }) : (errFormat ? t("chronos.auth.resetPassword.UseLatinAndNumber") : '')
      }));
    }
  }, [state.channel, state.password]);

  const changeStateField = (field: string, value: any) => {
    setState(state => ({
      ...state,
      channelMode: 'normal',
      channelMessage: '',
      passwordMode: 'normal',
      passwordMessage: '',
      [field]: value
    }));
  }

  const submitForm = async (evt: any) => {
    evt && evt.preventDefault();
    store.setLoading(true);
    // const lng = await api.getRegion();

    const token = await captchaRef.current?.executeAsync();
    if (!token) {
      console.error('Captcha error')
      setState(state => ({
        ...state,
        channelMode: 'error',
        channelMessage: 'Заполните каптчу'
      }));

      store.setLoading(false);
      return;
    }

    try {
      const payload = {
        channel: state.channel.trim(),
        password: state.password.trim(),
        partner: store.auth.partner,
        language: i18n.language,
        captchaToken: token
      };

      // debugger
      const result = await api.register(payload);
      store.auth.setRegisterId(result.id);
      store.auth.setChannel(state.channel);
      store.auth.setPassword(state.password);


      gtmSend({'event': 'singUp-successed', 'user_id': result.id ? result.id : ''})

      navigate('/confirmation');
    } catch (error: any) {
      console.log(error, ApiErrorDescriptions);
      const { type, message } = ApiErrorDescriptions[error.message];
      setState(state => ({
        ...state,
        channelMode: type,
        channelMessage: t(message)
      }));
    } finally {
      store.setLoading(false);
    }
  }

  return (
    <PageContainer
      backButton={<BackButton to='/signin' caption={t("chronos.auth.forgotPassword.backEntrance")}/>}
    >
      <Form className="form register" autoComplete='off' onSubmit={submitForm}>
        <div className="form-header">
          <div className="form-title">{t("chronos.auth.signUp.accountRegistration")}</div>
          {/* <div className="form-subtitle">{t("chronos.auth.signUp.createAccount")}</div> */}
        </div>
        <div className="form-content">
          {/* <SocialButtons>
            <Button name="google-button" color="transparent" bordered={true} style={{ padding: '0.563rem 0.5rem' }} onClick={authWithGoogle}>
              <ButtonWithIcon><GoogleIcon /><span>{t("chronos.auth.login.signWithGoogle")}</span></ButtonWithIcon>
            </Button>
          </SocialButtons> */}
          {/* <div className="separator"><span>{t("chronos.auth.signUp.or")}</span></div> */}

          <InputContainer label={t("chronos.auth.signIn.email")} message={t(state.channelMessage)}>
            <Input
              size='big'
              value={state.channel}
              mode={state.channelMode}
              clearable
              readonly
              onFocus={(e: any) => e.target.removeAttribute('readonly')}
              onChange={(val: string) => changeStateField('channel', val)}
            />
          </InputContainer>

          <InputContainer label={t("chronos.auth.login.password")} message={t(state.passwordMessage)}>
            <Input
              size='big'
              value={state.password}
              type="password"
              mode={state.passwordMode}
              onChange={(val: string) => changeStateField('password', val)}
            />
          </InputContainer>

          <ReCAPTCHA
              sitekey="6LdqFGUnAAAAAFPjZAmWlI1FS7TQNxF6vIsf5JKR"
              size="invisible"
              ref={captchaRef}
          />

          <FormControls className='form-buttons'>
            <Button name="submit-button" size='big' type="submit" disabled={!isFormValid} >{t("chronos.auth.signUp.createdAccount")}</Button>
          </FormControls>

          <p className="form-confirm">
            {t("chronos.auth.signUp.pressing")} <ins>{t("chronos.auth.signUp.createdAccount")}</ins>, {t("chronos.auth.signUp.agreeWith")} <a href={store.privacyPolicy} target="_blank">{U("chronos.app.payments.paymentAgree.2")}</a> {t("base.union")}&nbsp;
            <a href={store.termsOfUse} target="_blank">{U("chronos.app.settings.payment.selectedFormations.termOfUse")}</a>
          </p>
          
        </div>
      </Form>

    </PageContainer>
  )
});