import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ReactComponent as ChevronRight } from 'src/assets/icons/system/chevron-right.svg';

export default function BackButton({
  to,
  caption = 'backbutton',
  className
}: {
  to: string;
  caption: string;
  className?: string;
}) {
  return (
    <BackButtonContainer className={className}>
      <ChevronRight className='chevron-right'/>
      <Link to={to}>{caption}</Link>
    </BackButtonContainer>
  );
}

export const BackButtonContainer = styled.div`
  display: inline-flex;
  position: relative;
  vertical-align: center;

  font-size: 0.875rem;
  color: var(--text-secondary);

  svg.chevron-right {
    width: 1.325rem;
    height: 1.325rem;
    transform: rotate(180deg);
  }

  a {
    margin-left: 0.5rem;
    align-self: center;
    text-decoration: none;
    font-weight: 500;
    color: inherit;
    opacity: 1;
  }

  &:hover {
    opacity: 0.87;
    a {
      text-decoration: underline;
    }
  }
`;