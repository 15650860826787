import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import planetImages from './images';
import planetIcons from './icons';
import planetData from './planets';

import { ReactComponent as ChevronRight } from 'src/assets/icons/system/chevron-right.svg';
import { useTranslation } from 'src/i18n/useTranslation';

export default function PlanetWidget () {

  const [planetIndex, setPlanetIndex] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(true);

  const currentPlanet = React.useMemo(() => {
    return planetData[planetIndex];
  }, [planetIndex]);

  const CurrentIcon = React.useMemo(() => {
    const name = planetData[planetIndex].name;
    return (planetIcons as any)[name];
  }, [planetIndex]);

  function getNextIndex(index?: number) {
    let nextIndex = (index || planetIndex) + 1;
    if (nextIndex > planetData.length - 1) {
      nextIndex = 0;
    }
    return nextIndex;
  }

  function getPreviousIndex(index?: number) {
    let nextIndex = (index || planetIndex) - 1
    if (nextIndex < 0) {
      nextIndex = planetData.length - 1;
    }
    return nextIndex;
  }

  function slideTo(step: number) {
    setLoading(true);
    if (step === 1) {
      setPlanetIndex(getNextIndex());
    } else {
      setPlanetIndex(getPreviousIndex());
    }
  }

  function onImageLoad(evt: any){
    setLoading(false);
  }
  const { t } = useTranslation();
  
  return (
    <PlanetWidgetContainer className={`planet-widget${loading ? ' loading' : ''}`}>
      <header>
        <div className="left-side">
          <div className="title">
            <div className="icon">{<CurrentIcon/>}</div>
            {t(currentPlanet.title)}
          </div>
          <div className="sub-title">{t("chronos.auth.index.speed")} {t(currentPlanet.speed)}</div>
        </div>
        <div className="right-side">
          <div className="column">
            <div className="caption">{t("chronos.auth.index.symbolRuler")}</div>
            <div className="data">{t(currentPlanet.archon) }</div>
          </div>
          <div className="column">
            <div className="caption">{t("chronos.auth.zodiacSigns")}</div>
            <div className="data">{t(currentPlanet.sign)}</div>
          </div>
        </div>
      </header>
      <footer>
        <div className="desc">{t(currentPlanet.desc) }</div>
        <div className="controls">
          <button name="prev-button" className="prev" onClick={() => { slideTo(-1) }}>{<ChevronRight/>}</button>
          <button name="next-button" className="next" onClick={() => { slideTo(1) }}>{<ChevronRight/>}</button>
        </div>
      </footer>
      <img className={`planet-image${ !loading ? ' loaded' : ''}`} src={(planetImages as any)[currentPlanet.name]} alt="" onLoad={onImageLoad} />
    </PlanetWidgetContainer>
  )
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
    /* transform: scale(0); */
  }

  100%{
    opacity: 1;
    /* transform: scale(1); */
  }
`;

const PlanetWidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100vh;
  width: 50vw;
  justify-content: space-between;
  background: #000000f9;

  & > header {
    display: flex;
    justify-content: space-between;
    padding: 2.43rem 3.063rem 0 3.06rem;
    z-index: 2;

    .title {
      display: inline-flex;
      align-items: center;
      margin-left: -0.5rem;
      font-size: 6rem;
      line-height: 6rem;
      font-weight: 500;
      letter-spacing: -0.025em;
      color: var(--text-primary);

      .icon {
        position: relative;
        display: flex;
        width: 4rem;
        height: 4rem;
        margin-right: 0.5rem;
        justify-content: center;
        align-items: center;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
    .sub-title {
      margin-top: 0.75rem;
      font-size: 0.875rem;
      line-height: 75%;
      font-weight: normal;
      color: var(--text-secondary);
    }

    .right-side {
      display: flex;
      gap: 4.625rem;

      .column {
        position: relative;
        margin-top: 0.6rem;
        text-align: right;
        font-weight: 500;

        .caption {
          margin-bottom: 0.75rem;
          font-size: 0.75rem;
          color: var(--text-secondary);
          word-wrap: normal !important;
        }
        .data {
          font-size: 0.875rem;
        }
      }
    }
  }

  & > .planet-image {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100%;
    opacity: 0;
  }

  & > .planet-image::not(.loaded) {
    animation: ${fadeIn} ease 0.8s reverse both;
  }

  & > .planet-image.loaded {
    animation: ${fadeIn} ease 0.8s both;
  }

  & > footer {
    display: flex;
    justify-content: space-between;
    padding: 0 3rem 3rem;
    z-index: 2;

    .desc {
      position: relative;
      width: 80%;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: normal;
      color: var(--text-secondary);

      columns: 14.375rem 2;
      column-gap: 1.5rem;
    }

    .controls {
      position: relative;
      display: flex;
      align-items: flex-end;

      button {
        position: relative;
        display: inline-flex;
        box-sizing: border-box;
        appearance: none;
        width: 2.25rem;
        height: 2.25rem;
        border-radius: 50%;
        align-items: center;
        color: var(--text-secondary);
        background-color: #000000f9;
        border: 1px solid var(--element);
        transition: border-color 0.2s ease;
        cursor: pointer;

        &:hover {
          border-color: var(--input-border-hover);
        }
      }
      button + button {
        margin-left: 1rem;
      }

      button.prev svg {
        transform: rotate(180deg);
      }
    }
  }

  &:after {
    display: none;
    position: absolute;
  }

  &.loading {

    &:after {
      display: block;
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      width: 3em;
      height: 3em;
      margin-top: -1.5rem;
      margin-left: -1.5rem;
      font-size: 0.625rem;
      text-indent: -9999em;
      border-top: 0.3em solid rgba(255, 255, 255, 0.2);
      border-right: 0.3em solid rgba(255, 255, 255, 0.2);
      border-bottom: 0.3em solid rgba(255, 255, 255, 0.2);
      border-left: 0.3em solid #ffffff;
      border-radius: 50%;

      transform: translateZ(0);
      animation: load 1.1s infinite linear;

      z-index: 778;
      @keyframes load {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
`;