
import SignUp from 'src/pages/auth/SignUp';
import SignIn from 'src/pages/auth/SignIn';
import LogIn from 'src/pages/auth/LogIn';
import SignInWithPhone from 'src/pages/auth/SignInWithPhone';
import ConfirmationEmail from 'src/pages/auth/ConfirmationEmail';
import ForgotPassword from 'src/pages/auth/ForgotPassword';
import ConfirmationRestorePass from 'src/pages/auth/ConfirmationRestorePass';
import ResetPassword from 'src/pages/auth/ResetPassword';

export interface IRoute {
  path: string;
  element: JSX.Element;
  title: string;
}

export default {
  "signin": {
    path: 'signin',
    element: <SignIn />,
    title: 'chronos.auth.title.signin',
  },
  "login": {
    path: 'login',
    element: <LogIn />,
    title: 'chronos.auth.title.login'
  },
  "signup": {
    path: 'signup',
    element: <SignUp />, 
    title: 'chronos.auth.signUp.accountRegistration'
  },
  "signin-phone": {
    path: 'signin-phone',
    element: <SignInWithPhone />,
    title: 'chronos.auth.title.signinPhone'
  },
  "confirmation": {
    path: 'confirmation',
    element: <ConfirmationEmail />,
    title: 'chronos.auth.title.confirmation'
  },
  "password-forgot": {
    path: 'password-forgot',
    element: <ForgotPassword />,
    title: 'chronos.auth.title.forgotPassword'
  },
  "confirmation-restore": {
    path: 'confirmation-restore',
    element: <ConfirmationRestorePass />,
    title: 'chronos.auth.title.confirmationRestore'
  },
  "reset-password": {
    path: 'reset-password',
    element: <ResetPassword />,
    title: 'chronos.auth.title.resetPassword'
  }
} as { [key: string]: IRoute}