const storage = localStorage

export default {
  set: function (key: string, value: string) {
    return storage.setItem(key, value)
  },
  setWithExpiry(key: string, value: string, expiry: number) {
    const item = {
      value,
      expiry: Date.now() + expiry
    }
    return storage.setItem(key, JSON.stringify(item))
  },
  getWithExpiry(key: string) {
    const itemStr = storage.getItem(key)
    if (!itemStr) return null
    if (itemStr === 'undefined') {
      storage.removeItem(key)
      return null
    }
    const item = JSON.parse(itemStr)

    if (Date.now() > item.expiry) {
      storage.removeItem(key)
      return null
    }
    return item.value
  },
  get: function (key: string) {
    return storage.getItem(key)
  },
  remove: function (key: string) {
    return storage.removeItem(key)
  },
  removeAll: function () {
    return storage.clear()
  }
}

export const jidParamName = 'jid';
export const refParamName = 'ref';
export const fromParamName = 'from';
export const partnerParamName = 'partner';
export const authTypeParamName = 'at';
export const weakParamName = 'weak';
export const langParamName = 'lang';
export const hashParamName = 'hash';

export type ParamsType =
  typeof jidParamName |
  typeof refParamName |
  typeof fromParamName |
  typeof partnerParamName |
  typeof authTypeParamName |
  typeof weakParamName |
  typeof langParamName |
  typeof hashParamName

export const params: ParamsType[] = [
  jidParamName,
  refParamName,
  fromParamName,
  partnerParamName,
  authTypeParamName,
  weakParamName,
  langParamName,
  hashParamName,
]
