import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, useLocation } from 'react-router-dom';
import './styles/variables.css';

import './styles/index.css';
import App from './App';
import { useTranslation } from './i18n/useTranslation';
import routes from './routes';

// window.document.domain = process.env.NODE_ENV === 'development' ? 'localhost' : 'chronos.mg';

console.log('******* DROP CONSOLE.LOG not working')

const PageTitleProvider = () => {
  const location = useLocation();
  const { t } = useTranslation();

  React.useEffect(() => {
    const [, path = ''] = location.pathname.split('/');
    const title = routes[path]?.title || 'chronos.auth.title.login';
    
    setTimeout(() => {
      document.title = `${t(title)} - Chronos ID`;
    }, 300);
  }, [location]);

  return (null);
}

ReactDOM.render(
  <HashRouter>
    <React.StrictMode>
      <PageTitleProvider/>
      <App />
    </React.StrictMode>
  </HashRouter>,
  document.getElementById('root')
);

