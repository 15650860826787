import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    //debug: true,
    //resources: { dev: { common: {}}},
    backend: {
      loadPath: `${process.env.REACT_APP_S3}/i18n/7/${process.env.NODE_ENV === 'production' ? 'master' : 'dev'}/{{lng}}.json`,
      crossDomain: true,
      allowMultiLoading: true,
    },
    fallbackLng: 'unknown',
    load: 'currentOnly',
    ns: ['common'],
    defaultNS: 'common',
    //partialBundledLanguages: true,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage'],
      lookupLocalStorage: 'i18nextLng',
    },
    react: {
      useSuspense: false,
    }
  });

export default i18n;