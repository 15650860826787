import { makeAutoObservable } from 'mobx';
import JWT from 'jsonwebtoken';
import ls, { jidParamName, refParamName, fromParamName, partnerParamName, authTypeParamName, hashParamName } from 'src/services/ls';
import { TabsCommutator } from 'src/utils';

import api from 'src/services/api';
import i18n from 'src/i18n/i18n';

interface IUserData {
  avatarUrl: string;
  email: string;
  exp: number;
  firstName: string;
  iat: number;
  lastName: string;
  phoneNumber: string;
  secure: boolean;
  sessionId: number;
  type: 'email' | 'phone' | 'google' | 'vk' | 'yandex';
  userId: string;
}

export const ON_TOKEN_FETCH_KEY = 'fetch_token';

export default class AuthStore {

  private _channel: string = '';
  setChannel(val: string) {
    this._channel = val;
    ls.set('ch', `${val}`);
  }
  get channel() {
    return this._channel || ls.get('ch');
  }

  private _password: string = '';
  setPassword(val: string) {
    this._password = val;
  }
  get password() {
    return this._password;
  }

  private _registerId: number = 0;
  setRegisterId(val: number) {
    this._registerId = val;
    ls.set('ri', `${val}`);
  }
  get registerId() {
    return (this._registerId || Number(ls.get('ri')));
  }

  // search params
  private _jid: string | null = ls.get(jidParamName);
  setJid(val: string | null) {
    this._jid = val;
    if (val) {
      ls.set(jidParamName, val);
    } else {
      ls.remove(jidParamName);
    }
  }
  get jid() {
    return this._jid;
  }

  private _referrer: string = ls.get(refParamName) || process.env.REACT_APP_CHRONOS_PROFILE_URL!;
  setReferrer(val: string) {
    this._referrer = val;
    ls.set(refParamName, val);
  }
  get referrer() {
    return this._referrer;
  }

  private _from: string | null = ls.get(fromParamName);
  setFrom(val: string) {
    this._from = val;
    ls.set(fromParamName, val);
  }
  get from() {
    return this._from;
  }

  private _partner: string | null = ls.getWithExpiry(partnerParamName);
  setPartner(val: string) {
    this._partner = val;
    const expiry = 30 * (24 * 60 * 60 * 1000) // 30дн
    ls.setWithExpiry(partnerParamName, val, expiry)
  }
  get partner() {
    return this._partner;
  }

  private _hash: string | null = ls.get(hashParamName);
  setHash(val: string | null) {
    this._hash = val;
    ls[val ? 'set' : 'remove'](hashParamName, val as string);
  }

  get hash() {
    return this._hash;
  }

  private _authType: string | null = ls.get(authTypeParamName);
  setAuthType(val: string) {
    this._authType = val;
    ls.set(authTypeParamName, val);
  }
  get authType() {
    return this._authType;
  }

  get User(): IUserData | null {
    return this.getUserFromJwt() as IUserData;
  }

  private getUserFromJwt() {
    if (!this._jid) return null;
    return JWT.decode(this._jid);
  }

  clearData() {
    this.setJid(null);
  }

  redirectAction(url: string, token: string) {
    const isParams = /.+\?.+=.+/.test(url);
    const ref = decodeURIComponent(`${url}${isParams ? `&` : `?`}token=${token}`);
    window.location.href = ref;
  }

  async tryRedirectToReferredApp({
    url,
    params = { ref: '', weak: false, from: '', partner: ''},
    onRefresh,
    success,
    fail
  }: {
    url: string;
    params?: { ref: string; weak: boolean; from: string; partner: string };
    onRefresh?(jid: string): void;
    success?: () => void;
    fail?(err: string): void;
  }): Promise<void> {

    const conversion = `${params.from},${params.ref}`;

    try {
      const result = await api.cb({ conversion });
      success?.();
      TabsCommutator.emit(ON_TOKEN_FETCH_KEY, result.code);
      this.redirectAction(url, result.code);
    } catch (err: any) {
      if (err.status === 401) {
        if (params.weak) {
          this.redirectAction(url, 'weak');
          throw err;
        }
        (async () => {
          try {
            const { data: { jid } } = await api.refresh();
            jid && onRefresh?.(jid);
            const language = await api.getLanguage(jid);
            language && i18n.changeLanguage(language);
            const result = await api.cb({ conversion });
            TabsCommutator.emit(ON_TOKEN_FETCH_KEY, result.code);
            this.redirectAction(url, result.code);
          } catch (e: any) {
            fail?.(e);
            // throw e;
          }
        })();
      }
    }
  }

  async autoLogin(hash: string) {

    return new Promise(async (resolve, reject) => {

      // this.tryRedirectToReferredApp({
      //   url: this.referrer,
      //   onRefresh: jid => this.setJid(jid),
      //   success: () => { this.setHash(null) },
      //   fail: async (error: any) => {

          try {
            const result = await api.login({ hash });
            this.setJid(result.jid);

            await this.tryRedirectToReferredApp({
              url: this.referrer,
              success: () => {
                this.setHash(null);
              },
              fail: error => {
                throw error;
              }
            })

            resolve(result.jid);
          } catch (err: any) {
            if (err.status === 409) {
              reject(err);
            }
          }
        // }
      // })

    })

  }

  constructor() {
    makeAutoObservable(this)
  }
}
