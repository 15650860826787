import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import store from 'src/store';
import { useTranslation } from 'src/i18n/useTranslation';
import { Button, NotificationPopup, Notifications } from 'src/libs';

export default function PageContainer({
  backButton,
  actionButton,
  children
}: {
    backButton?: React.ReactChild;
    actionButton?: React.ReactChild;
    children: React.ReactChild | React.ReactChild[];
}) {

  const [supportInfo, setSupportInfo] = React.useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <PageMainContainer className="page-container">
      <header>
        <div className="side left">{backButton}</div>
        <div className="side right">{actionButton}</div>
      </header>
      <main>{children}</main>
      <footer>
        <ul>
          <li onClick={() => setSupportInfo(true) }><a>{t("chronos.app.sidebar.support")}</a></li>
          <li><a href={store.termsOfUse} target="_blank">{t("chronos.auth.pageContainer.contract")}</a></li>
          <li><a href={store.privacyPolicy} target="_blank">{t("chronos.auth.pageContainer.confidentiality")}</a></li>
          <li><a href={store.onlinePayments} target="_blank">{t("chronos.auth.pageContainer.onlinePayments")}</a></li> 
        </ul>
      </footer>
      <Notifications />
      
      {supportInfo && <NotificationPopup
        size='small'
        type='info'
        title={t("chronos.app.sidebar.writeToUs")}
        content={<>
          <div>
            {t("chronos.auth.sidebar.supportInfo")} <a>support@chronos.mg</a>
          </div>
          
        </>}
        buttons={<Button size='big' onClick={() => setSupportInfo(false)}><a href="mailto:support@chronos.mg">{t("base.write")}</a></Button>}
        onClose={() => setSupportInfo(false)}
      />}
    </PageMainContainer>
  )
};

const PageMainContainer = styled.section`
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1.375rem 1.5rem;
  overflow-y: auto;

  & > header {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    & > .side {
      flex: 1;

      &:empty {
        flex: 0;
      }

      &.right {
        text-align: right;

        @media (max-width: 600px) {
          text-align: center;
        }
      }
    }
  }

  & > main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 2;
  }

  & > footer {
    display: flex;
    justify-content: center;
    margin-top: 3rem;

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        position: relative;
        font-size: 0.75rem;
        line-height: 1.5rem;
        color: var(--text-third);
        cursor: pointer;
        
        a {
          text-decoration: none;
          color: inherit;
        }

        a:hover {
          text-decoration: underline;
        }
      }

      li:before {
        content: "\\2022";
        margin: 0 0.5rem;
        color: inherit;
      }

      li:first-child:before {
        content: '';
      }
    }
  }

  .form {
    display: block;
    position: relative;
    width: 45%;
    margin: auto auto;

    a {
      color: var(--accent-blue);
    }
  }

  @media (max-width: 1024px) {
    .form {
      width: 45%;
    }
  }

  @media (max-width: 768px) {
      .form {
        width: 50%;
      }
  }

  @media (max-width: 480px) {
      .form {
        width: 100%;
      }
      & > main {
        flex-shrink: 0;
        flex-basis: auto; 
      }
  }

  .form-header {
    position: relative;
    margin-bottom: 2.25rem;
    padding: 0 1rem;
  }

  .chronos-logo {
    display: block;
    position: relative;
    width: 6rem;
    height: 6rem;
    margin: 0 auto;
    margin-bottom: 2.25rem;

    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  .form-title {
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 500;
    color: var(--text-primary);
    text-align: center;
    margin-bottom: 0.75rem;
  }

  .form-subtitle {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: normal;
    color: var(--text-secondary);
    text-align: center;
  }

  .form-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    width: 80%;
    margin: 0 auto;

    & > * {
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    .form-content {
      width: 100%;
    }
  }

  .form-buttons {
    position: relative;
    margin-top: 1.75rem;
    text-align: center;

    > button + button {
      margin-left: 1rem;
    }
  }

  .form-confirm {
    position: relative;
    margin-top: 1.5rem;
    text-align: center;
    font-size: 0.75rem;
    line-height: 1rem;
    color: var(--text-third);

    ins {
      text-decoration: none;
      color: var(--text-primary);
    }

    a {
      text-decoration: none;
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .separator {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 1px;
    margin: 1rem 0 1.5rem 0;
    background-color: var(--element-light);

    span {
      display: inline-block;
      padding: 0 2rem;

      font-size: 0.875rem;
      line-height: 0.875rem;
      font-weight: 500;
      color: var(--text-secondary);

      background-color: var(--background);
    }
  }

  /* input {
    font-size: 1rem;
    font-weight: 500;
  } */
`;
