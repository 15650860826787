import React from 'react';
import styled from 'styled-components';
import { Outlet } from "react-router-dom";
import { observer } from 'mobx-react';
import PlanetWidget from 'src/components/PlanetWidget/index';

import store from 'src/store';
import { TabsCommutator } from 'src/utils';
import { ON_TOKEN_FETCH_KEY } from 'src/store/Auth';

export default observer(function AuthContainer() {

  React.useEffect(() => {
    TabsCommutator.on(ON_TOKEN_FETCH_KEY, (params: StorageEvent) => {
      store.auth.redirectAction(store.auth.referrer!, params.newValue!);
    });

    return () => TabsCommutator.off(ON_TOKEN_FETCH_KEY);
  }, []);


  return (
    <>
      <div className="widget"><PlanetWidget /></div>
      <AuthMainContainer className={`auth${store.loading ? ' loading' : ''}`}>
        <Outlet />
      </AuthMainContainer>
    </>
  );
});

const AuthMainContainer = styled.div`
  position: relative;

  &:before {
    display: none;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-color: var(--workspace-background);
    opacity: 1;
    z-index: 777;
    transition: opacity 0.2s ease 0.1s;
  }

  &:after {
    display: none;
    position: absolute;
  }

  &.loading {

    &:before {
      display: block;
      opacity: 0.8;
    }

    &:after {
      display: block;
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      width: 3em;
      height: 3em;
      margin-top: -1.5rem;
      margin-left: -1.5rem;
      font-size: 0.625rem;
      text-indent: -9999em;
      border-top: 0.3em solid rgba(255, 255, 255, 0.2);
      border-right: 0.3em solid rgba(255, 255, 255, 0.2);
      border-bottom: 0.3em solid rgba(255, 255, 255, 0.2);
      border-left: 0.3em solid #ffffff;
      border-radius: 50%;

      transform: translateZ(0);
      animation: load 1.1s infinite linear;

      z-index: 778;
      @keyframes load {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
`;