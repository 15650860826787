import React from 'react';
import styled from 'styled-components';
import { ReactMemo } from 'src/hooks';

export default ReactMemo(function CountDown({
  minutes,
  startMessage,
  endMessage,
  onFinish,
  onClick
}: {
  minutes: number;
  startMessage: string;
  endMessage: string;
  onFinish?: () => void;
  onClick?: () => void;
}) {
  let setIntervalId: any = null;
  const [timer, setTimer] = React.useState<string>('');
  const [finished, setFinished] = React.useState<boolean>(false);

  React.useEffect(() => {
    start();
    return () => clearInterval(setIntervalId);
  }, []);

  const currentMessage = React.useMemo(() => {
    if (!finished) {
      return <>{startMessage.replace('{{minutes}}', timer || minutes.toString())}</>
    } else {
      return <span className="again-button" onClick={() => onClick?.()}>{endMessage}</span>
    }
  }, [timer, finished]);

  const start = () => {
    if(!minutes) return;

    const d = new Date()
    d.setMinutes(d.getMinutes() + Math.abs(minutes))

    setIntervalId = setInterval(() => {
      const t = Number(d) - Date.now()

      if (t <= 0) {
        setTimer('');
        setFinished(true);
        onFinish?.();
        clearInterval(setIntervalId);
        return;
      }

      const seconds = Math.floor((t / 1000) % 60).toString()
      const minutes = Math.floor((t / 1000 / 60) % 60).toString()

      setTimer(`${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`);
    }, 1000)
  }

  return (
    <Container>{currentMessage}</Container>
  );
});

const Container = styled.span`
  position: relative;

  .again-button {
    text-decoration: underline;
    cursor: pointer;
  }
`;