import ls from 'src/services/ls';
import JWT from 'jsonwebtoken';
import { ChronosApi, IToken, S3Urls } from '../libs';

export const s3urls = new S3Urls();

export class ApiError extends Error {
  private _errors: string[] = [];

  get errors() {
    return this._errors;
  }

  constructor(errors: string[]) {
    super(errors.join(";"));
    this._errors = errors;
  }
}

export const authToken = {
  _tokenKeyName: 'token',

  _getData(): IToken | undefined {
    let data: any = ls.get(this._tokenKeyName);
    if (data) {
      data = JSON.parse(data);
    }
    return data;
  },
  _checkTokenData() {
    let data = this._getData();
    if (!data) {
      data = { access: "", refresh: "" };
      ls.set(this._tokenKeyName, JSON.stringify(data));
    }
    return data;
  },
  setToken(type: 'access' | 'refresh', token: string) {
    let data = this._checkTokenData() as any;
    data[type] = token;
    ls.set(this._tokenKeyName, JSON.stringify(data));
  },

  getToken(type: 'access' | 'refresh') {
    const data = this._getData() as any;
    return data ? data[type] : data;
  }
}

// class BackendApi extends ChronosApi {
//   constructor() {
//     super(process.env.REACT_APP_BACKEND as string, process.env.REACT_APP_API_AUTH_HOST as string);
//   }
// }
// @ts-ignore
// const backendApi = new ChronosApi(process.env.REACT_APP_BACKEND as string, process.env.REACT_APP_API_AUTH_HOST as string);

class Api {
  private _host: string;

  constructor(host: string) {
    this._host = host;
  }

  private async query(method: string, uri: string, data?: any): Promise<any> {
    const headers: {
      [key: string]: string;
    } = {
      "Content-Type": "application/json;charset=utf-8",
      "Accept": 'application/json',
    };

    if (uri.includes('/login')) {
      headers['front-service-location'] = window.location.href;
    }

    return fetch(`${this._host}/${uri}/`, {
      method,
      headers,
      mode: 'cors',
      credentials: 'include',
      body: ['POST', 'PUT', 'DELETE'].includes(method) && data ? JSON.stringify(data) : null
    })
      .then(async (response) => {
        return response.ok ? response : Promise.reject(response)
      })
      .then(response => {
        return response.json();
      })
      .catch(async (response: Response) => {
        const error = {...await response.json(), status: response.status}
        throw error;
      })
  }

  login(data: { channel?: string; password?: string; hash?: string; }) {
    return this.query('POST', `auth/login`, data);
  }

  register(data: { channel: string; password: string; partner: string | null, language?: string, captchaToken: string }) {
    return this.query('POST', `auth/register`, {
      ...data,
    });
  }

  resendRegisterCode(id: number | null) {
    if (id == null) {
      throw Error(`Api error: register id is ${typeof id}`);
      return;
    }
    return this.query('POST', 'auth/resend', { id });
  }

  confirm(data: { channel: string | null; code: string, captchaToken: string }) {
    if (!data.channel) {
      throw Error(`Api error: confirm channel is ${typeof data.channel}`);
    }
    return this.query('POST', `auth/confirm`, {
      ...data,
    });
  }

  restore({ channel, token }: { channel: string | null; token: string }) {
    if (!channel) {
      throw Error(`Api error: restore channel is ${typeof channel}`);
    }

    return this.query('POST', `password/restore`, { channel, token });
  }

  restoreConfirm(data: { channel: string | null; code: string, captchaToken: string }) {
    return this.query('POST', `password/restore/confirm`, { ...data });
  }

  changePassword(data: { newPassword: string; confirmPassword: string, captchaToken: string }) {
    return this.query('POST', `password/change`, data);
  }

  cb({ conversion }: { conversion: string | null }) {
    return this.query('POST', `auth/cb`, { conversion });
  }

  refresh() {
    return this.query('GET', `auth/refresh`);
  }

  changeLanguage(token: string, language: string) {
    // FIXME: do it on the server side
    //@ts-ignore
    const { userId } = JWT.decode(token);
    if (!userId) return;
    return this.query('PUT', `/user/${userId}/language`, { language });
  }

  async getLanguage(token: string) {
    // FIXME: do it on the server side
      //@ts-ignore
    const { userId } = JWT.decode(token);
    if (!userId) return '';
    const resp = await this.query('GET', `user/${userId}/language`);
    return resp.data;
  }

}

export const ApiErrorDescriptions: { [key: string]: { message: string; type: 'warning' | 'error' } } = {
  'INVALID_CREDENTIALS': {
    message: "chronos.auth.api.WrongLoginOrPassword",
    type: "error"
  },
  'WRONG CHANNEL': {
    message: "chronos.auth.api.wrongLogin",
    type: "error"
  },
  'SMTH WRONG': {
    message: "chronos.auth.api.noUserWithThisLogin",
    type: 'error'
  },
  'user not found': {
    message: "chronos.auth.api.userNotDefined",
    type: 'error',
  },
  'already exists': {
    message: "chronos.auth.api.userAlredyExists",
    type: 'error'
  },
  'invalid token': {
    message: "chronos.auth.api.invalidCode",
    type: 'error'
  },
  'token expired': {
    message: "chronos.auth.api.codeExpired",
    type: 'error'
  }
}

export const GOOGLE_AUTH_LINK = `${process.env.REACT_APP_API_AUTH_HOST}/auth/google`;
export const VK_AUTH_LINK = `${process.env.REACT_APP_API_AUTH_HOST}/auth/vk`;
export const YANDEX_AUTH_LINK = `${process.env.REACT_APP_API_AUTH_HOST}/auth/yandex`;

export const authWithGoogle = () => {
  
  let iFrame: Window | null = null;

  for (const i in window.parent.frames) {
    if (window.parent?.frames?.[i]?.name === "auth-frame") {
      iFrame = window.parent.frames[i];
      break;
    }
  }

  if (iFrame) {
    window.top?.postMessage('google_auth', "*");
  } else {
    window.location.href = GOOGLE_AUTH_LINK;
  }

}

const api = new Api(process.env.REACT_APP_API_AUTH_HOST as string);

export default api;
