import React from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import AuthContainer from 'src/pages/auth/AuthContainer';
import SignIn from 'src/pages/auth/SignIn';

import routes from './routes';

import { jidParamName, refParamName, fromParamName, partnerParamName, weakParamName, langParamName, hashParamName } from 'src/services/ls';
import store from 'src/store';
import i18n from 'src/i18n/i18n';
import { changeLanguage } from 'i18next';
import { getLocalLanguage, languages } from './utils';
import { Loader } from './libs';

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [goAhead, setGoAhead] = React.useState(false);
  const [isInit, setIsInit] = React.useState(false);
  const sp = new URLSearchParams(location.search);

  React.useEffect(() => {
    if (location.pathname !== '/') {
      setGoAhead(true);
      return;
    }

    const jidParam = sp.get(jidParamName) as string;
    const refParam = sp.get(refParamName) as string;
    const fromParam = sp.get(fromParamName) as string;
    const partnerParam = sp.get(partnerParamName) as string;
    const weakParam = sp.get(weakParamName) as string;
    const hashParam = sp.get(hashParamName) as string;

    // debugger

    // если пришел jid после успешной авторизации через oAuth
    if (jidParam) {
      store.auth.setJid(jidParam);
    }

    if (refParam) {
      store.auth.setReferrer(refParam.replace(/hash/g, '#')); // из админки может прийти https://admin-dev.chronos.mg/hash/reports
    }

    if (fromParam) {
      store.auth.setFrom(fromParam);
    }

    if (partnerParam) {
      store.auth.setPartner(partnerParam);
    }

    if (hashParam) {
      store.auth.autoLogin(hashParam)
        .then()
        .catch((err) => {
          store.auth.clearData();
          // console.log('* autologin err -', err)
          navigate('/signin', { replace: true });
        })

      return;
    }

    store.auth.tryRedirectToReferredApp({
      url: store.auth.referrer,
      params: {
        ref: refParam,
        weak: Boolean(weakParam === 'true'),
        from: fromParam,
        partner: partnerParam,
      },
      onRefresh: jid => store.auth.setJid(jid),
      fail: (error: any) => {
        // редирект не удался
        console.log('first redirect fail');
        // есть последний авторизованный юзер - показываем приветствие и форму быстрого входа
        if (error.status === 401 && store.auth.User && store.auth.User.sessionId) {
          navigate('/login', { replace: true });
        } else {
          store.auth.clearData();
          navigate('/signin', { replace: true });
        }
      }
    });
  }, [location]);

  React.useEffect(() => {
    const langParam = sp.get(langParamName) ?? '';

    if (languages.includes(langParam)) {
      changeLanguage(langParam)
    }

    i18n.on('initialized', async () => {
      try {
        if (i18n.language === 'unknown' && !langParam) {
          const language = getLocalLanguage();//await api.getRegion();
          changeLanguage(language ?? 'en');
        }
      } catch (e) {
        console.log(e)
      }
    });

    i18n.on('loaded', () => setIsInit(i18n.language !== 'unknown'));
  }, []);

  if (!goAhead || !isInit) return <Loader/>;

  return (
    <Routes>
      <Route path="/" element={<AuthContainer />}>
        <Route index element={<SignIn />} />
        {
          Object.values(routes).map(({ path, element }, idx) => <Route key={`route_${idx}`} path={path} element={element} />)
        }
      </Route>
    </Routes>
  );
}

export default App;
