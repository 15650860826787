import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactMemo } from 'src/hooks';
import PageContainer from 'src/components/PageContainer';
import { Form, FormControls } from 'src/components/StyledCommon';
import CountDown from 'src/components/CountDown';
import { TabsCommutator } from 'src/utils';
import BackButton from 'src/components/BackButton';

import store from 'src/store';
import api, { ApiErrorDescriptions } from 'src/services/api';
import { useTranslation } from 'src/i18n/useTranslation';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, Input, InputContainer, TInputMode } from 'src/libs';

const tabsCommutationKey = 'confirm-restore-pass';

export default ReactMemo(function ConfirmationRestorePass() {
  const navigate = useNavigate();
  const params = useLocation();
  const [confirmCode, setConfirmCode] = React.useState<string>('');
  const [confirmMode, setConfirmMode] = React.useState<TInputMode>('normal');
  const [confirmMessage, setConfirmMessage] = React.useState<string>('');
  const [showCountDown, setShowCountDown] = React.useState<boolean>(true);
  const captchaRef = React.useRef<ReCAPTCHA>(null)

  React.useEffect(() => {
    const sp = new URLSearchParams(params.search);
    const token = sp.get('confirm_token');
    if (token) {
      const { channel, code } = JSON.parse(atob(token));
      store.auth.setChannel(channel);
      setConfirmCode(code);
      setTimeout(() => {
        onSubmit({ channel, code });
      }, 100);
      console.log('confirm for -', channel, code)
    }
  }, [params]);

  React.useEffect(() => {
    TabsCommutator.on(tabsCommutationKey, () => {
      alert(tabsCommutationKey)
    })

    return () => TabsCommutator.off(tabsCommutationKey);
  }, []);

  React.useEffect(() => {
    setConfirmMode('normal');
    setConfirmMessage('');
  }, [confirmCode]);

  const onSubmit = async (params: { evt?: any; channel?: string; code?: string; }) => {
    params.evt && params.evt.preventDefault();
    store.setLoading(true);
    setShowCountDown(false);
    try {
      const token = await captchaRef.current?.executeAsync()
      if (token) {
        const result = await api.restoreConfirm({ channel: (params.channel || store.auth.channel), code: params.code || confirmCode, captchaToken: token });
        setShowCountDown(true);
        navigate('/reset-password');
        console.log('register email confirm - ', result)
      } else {
        console.error('Captcha error')
      }
    } catch (error: any) {
      const { type, message } = ApiErrorDescriptions[error.message];
      setShowCountDown(false)
      setConfirmMode(type);
      setConfirmMessage(t(message));
      console.log('register confirm err - ', JSON.stringify(error));
    } finally {
      store.setLoading(false);
      TabsCommutator.emit(tabsCommutationKey, true);
    }
  }

  const requestCodeAgain = async () => {
    store.setLoading(true);
    setShowCountDown(false);
    try {
      const token = await captchaRef.current?.executeAsync()
      if (token) {
        const result = await api.restore({ channel: store.auth.channel, token });
        setShowCountDown(true);
      } else {
        console.error('Captcha error')
      }
    } catch (error) {

    } finally {
      store.setLoading(false);
    }
  }
  const { t } = useTranslation();

  return (
    <PageContainer
      backButton={<BackButton to='/signup' caption={t("chronos.auth.confirmationEmail.changeData")}/>}
    >
      <Form className="form signin" onSubmit={(evt) => onSubmit({ evt })}>
        <div className="form-header">
          <div className="form-title">{t("chronos.auth.confirmationRestorePass.restorePass")}</div>
          <div className="form-subtitle">{t("chronos.auth.confirmationEmail.sentTo")}<a href={`mailto:${store.auth.channel}`}>{store.auth.channel}</a>{t("chronos.auth.confirmationEmail.code_enterPhone")}</div>
        </div>
        <div className="form-content">
          <InputContainer
            label={t("chronos.auth.confirmationEmail.enterCode")}
            message={
              (showCountDown
                ? <CountDown minutes={1} startMessage= {t("chronos.auth.confirmationEmail.codeNotComming")} endMessage={t("chronos.auth.confirmationEmail.requestNewCode")} onClick={requestCodeAgain} />
                : confirmMessage
              ) as any
            }>
            <Input
              name="confirm-code"
              size='big'
              value={confirmCode}
              mode={confirmMode}
              placeholder="****"
              onChange={(val: string) => setConfirmCode(val)}
            />
          </InputContainer>

          <FormControls className='form-buttons'>
            <Button name="submit-button" size='big' type="submit" disabled={!confirmCode}>{t("base.confirm")}</Button>
          </FormControls>
        </div>
      </Form>

      <ReCAPTCHA
        sitekey="6LdqFGUnAAAAAFPjZAmWlI1FS7TQNxF6vIsf5JKR"
        size="invisible"
        ref={captchaRef}
      />
    </PageContainer>
  );
});
