import moon from './moon.jpg';
import sun from './sun.jpg';
import mars from './mars.jpg';
import mercury from './mercury.jpg';
import neptune from './neptune.jpg';
import pluto from './pluto.jpg';
import saturn from './saturn.jpg';
import uranus from './uranus.jpg';
import venus from './venus.jpg';
import jupiter from './jupiter.jpg';

export default {
  sun,
  moon,
  mars,
  mercury,
  neptune,
  pluto,
  saturn,
  uranus,
  venus,
  jupiter
}