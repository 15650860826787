import { ReactComponent as jupiter } from './jupiter.svg';
import { ReactComponent as mars } from './mars.svg';
import { ReactComponent as mercury } from './mercury.svg';
import { ReactComponent as moon } from './moon.svg';
import { ReactComponent as neptune } from './neptune.svg';
import { ReactComponent as pluto } from './pluto.svg';
import { ReactComponent as saturn } from './saturn.svg';
import { ReactComponent as sun } from './sun.svg';
import { ReactComponent as uranus } from './uranus.svg';
import { ReactComponent as venus } from './venus.svg';

export default {
  jupiter,
  mars,
  mercury,
  moon,
  neptune,
  pluto,
  saturn,
  sun,
  uranus,
  venus
}