import { makeAutoObservable } from 'mobx';
import { s3urls } from 'src/services/api';
import AuthStore from './Auth'

class AppStore {

  auth: AuthStore = new AuthStore();

  _loading: boolean = false;
  setLoading(val: boolean) {
    this._loading = val;
  }
  get loading() {
    return this._loading
  }

  private _chronosUrl: string = 'https://chronos.mg';
  get chronosUrl() {
    return this._chronosUrl;
  }

  private _supportEmail: string = 'support@chronos.mg';
  get supportEmail() {
    return this._supportEmail;
  }

  private _termsOfUse = s3urls.termsOfUseRU;
  get termsOfUse() {
    return this._termsOfUse;
  }

  private _privacyPolicy = s3urls.privacyPolicyRU;
  get privacyPolicy() {
    return this._privacyPolicy;
  }

  private _onlinePayments = s3urls.onlinePaymentsRU;
  get onlinePayments() {
    return this._onlinePayments;
  }

  constructor() {
    makeAutoObservable(this);
  }
}

const store = new AppStore();

export default store;
