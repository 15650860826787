import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactMemo } from 'src/hooks';

import PageContainer from 'src/components/PageContainer';
import BackButton from 'src/components/BackButton';
import { Form, FormControls } from 'src/components/StyledCommon';

import store from 'src/store';
import api, { ApiErrorDescriptions } from 'src/services/api';
import { useTranslation } from 'src/i18n/useTranslation';
import i18n from 'src/i18n/i18n';
import { Button, Input, InputContainer, PhoneInput, TInputMode } from 'src/libs';

export default ReactMemo(function SignInWithPhone() {
  
  const navigate = useNavigate();

  const [channel, setChannel] = React.useState<string>('');
  const [channelMode, setChannelMode] = React.useState<TInputMode>('normal');
  const [channelMessage, setChannelMessage] = React.useState<string>('');
  
  const [password, setPassword] = React.useState<string>('');
  const [passwordMode, setPasswordMode] = React.useState<TInputMode>('normal');
  const [passwordMessage, setPasswordMessage] = React.useState<string>('');

  const isFormValid = React.useMemo(() => {
    return Boolean(channel && password);
  }, [channel, password]);

  const onSubmit = async (evt: any) => {
    evt && evt.preventDefault();
    store.setLoading(true);
    try {
      const result = await api.login({ channel: `+${channel.trim()}`, password: password.trim() });
      store.auth.setJid(result.jid);
      store.auth.tryRedirectToReferredApp({
        url: store.auth.referrer,
        success: () => {
          //
        },
        fail: error => {
          console.log('* from signin redirect err -', error)
        }
      })
    } catch (error: any) {
      const { type, message } = ApiErrorDescriptions[error.message];
      if (error.message === 'WRONG CHANNEL') {
        setChannelMode(type);
        setChannelMessage(t(message));
      } else {
        setChannelMode(type);
        setChannelMessage(t(message));
        setPasswordMode(type);
        setPasswordMessage(t(message));
      }
      
    } finally {
      store.setLoading(false);
    }
  }

  const { t } = useTranslation();

  return (
    <PageContainer
      backButton={<BackButton to='/signin' caption={t("chronos.auth.forgotPassword.backEntrance")} />}
    >
      <Form className="form signin" onSubmit={onSubmit}>
        <div className="form-header">
          <div className="form-title">{t("chronos.auth.resetPassword.signWithPhone")}</div>
          <div className="form-subtitle">{t("chronos.auth.resetPassword.profileUsedSite")}</div>
        </div>

        <div className="form-content">
          <InputContainer label={t("chronos.auth.signInWithPhone.numberPhone")} message={t(channelMessage)}>
            <PhoneInput
              name="channel"
              size='big'
              value={channel}
              mode={channelMode}
              country='ru'
              placeholder='+7'
              onChange={(val: string) => setChannel(val)}
              lang={i18n.language}
            />
          </InputContainer>

          <InputContainer label={t("chronos.auth.login.password")} message={t(passwordMessage)}>
            <Input
              name="password"
              size='big'
              value={password}
              type="password"
              mode={passwordMode}
              onChange={(val: string) => setPassword(val)}
            />
          </InputContainer>

          <FormControls className='form-buttons'>
            <Button name="submit-button" size='big' type="submit" disabled={!isFormValid}>{t("chronos.app.autorisation.signIn")}</Button>
            <Button name="forgot-button" type="button" size='big' color="transparent" onClick={() => navigate('/password-forgot')}>{t("chronos.auth.forgotPassword.forgotPassword")}</Button>
          </FormControls>
        </div>
      </Form>
    </PageContainer>
  );
});