import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactMemo } from 'src/hooks';
import BackButton from 'src/components/BackButton';
import PageContainer from 'src/components/PageContainer';
import { Form, FormControls } from 'src/components/StyledCommon';

import { PASS_MIN_LENGTH, alphaAndDigitValidation } from 'src/utils';

import store from 'src/store';
import api, { ApiErrorDescriptions } from 'src/services/api';
import { useTranslation } from 'src/i18n/useTranslation';
import { Button, Input, InputContainer, TInputMode } from 'src/libs';
import ReCAPTCHA from "react-google-recaptcha";

export default ReactMemo(function ResetPassword() {
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = React.useState<string>('');
  const [newPasswordMode, setNewPasswordMode] = React.useState<TInputMode>('normal');
  const [newPasswordMessage, setNewPasswordMessage] = React.useState<string>('');

  const [confirmPassword, setConfirmPassword] = React.useState<string>('');
  const [confirmPasswordMode, setConfirmPasswordMode] = React.useState<TInputMode>('normal');
  const [confirmPasswordMessage, setConfirmPasswordMessage] = React.useState<string>('');
  const { t } = useTranslation();
  const captchaRef = React.useRef<ReCAPTCHA>(null)

  // валидация
  React.useEffect(() => {
    
    if (newPassword) {
      const errFormat = !alphaAndDigitValidation(newPassword);
      const errLength = newPassword.length < PASS_MIN_LENGTH;
      const mode = errFormat || errLength ? 'error' : 'normal'
      const message = errLength ? t("chronos.auth.minLength", { minLength: PASS_MIN_LENGTH }) : (errFormat ? t("chronos.auth.resetPassword.UseLatinAndNumber") : '')
      setNewPasswordMode(mode);
      setNewPasswordMessage(message);
    }
    if (confirmPassword) {
      setConfirmPasswordMode(confirmPassword !== newPassword ? 'error' : 'normal');
      setConfirmPasswordMessage(confirmPassword !== newPassword ? t("chronos.auth.resetPassword.passwordMismatch") : '');
    }
  }, [newPassword, confirmPassword]);

  const onSubmit = async (evt: any) => {
    evt && evt.preventDefault();
    store.setLoading(true);
    try {
      const token = await captchaRef.current?.executeAsync()
      if (token) {
        const result = await api.changePassword({ newPassword: newPassword.trim(), confirmPassword: confirmPassword.trim(), captchaToken: token });
        navigate('/signin');
      } else {
        console.error('Captcha error');
      }
    } catch (error: any) {
      const { type, message } = ApiErrorDescriptions[error.message];
      setNewPasswordMessage(t(message));
      setConfirmPasswordMessage(t(message));
      console.log('change password err - ', JSON.stringify(error));
    } finally {
      store.setLoading(false);
    }
  }

  const isFormValid = React.useMemo(() => {
    const validNewPassword = newPassword && newPassword.length >= PASS_MIN_LENGTH && alphaAndDigitValidation(newPassword);
    return Boolean(validNewPassword && confirmPassword && newPassword === confirmPassword);
  }, [newPassword, confirmPassword]);

  return (
    <PageContainer
      backButton={<BackButton to='/signin' caption={t("chronos.auth.forgotPassword.backEntrance")}/>}
    >
      <Form className="form signin" onSubmit={onSubmit}>
        <div className="form-header">
          <div className="form-title">{t("chronos.auth.resetPassword.newPassword")}</div>
          <div className="form-subtitle">{t("chronos.auth.resetPassword.differentCase")}</div>
        </div>
        <div className="form-content">
          <InputContainer label={t("chronos.auth.resetPassword.newPassword")} message={t(newPasswordMessage)}>
            <Input
              name="password"
              size='big'
              value={newPassword}
              type="password"
              mode={newPasswordMode}
              readonly
              onFocus={(e: any) => e.target.removeAttribute('readonly')}
              onChange={(val: string) => setNewPassword(val)}
            />
          </InputContainer>
          <InputContainer label={t("chronos.auth.resetPassword.confirmPassword")} message={t(confirmPasswordMessage)}>
            <Input
              name="password-confirm"
              size='big'
              value={confirmPassword}
              type="password"
              mode={confirmPasswordMode}
              onChange={(val: string) => setConfirmPassword(val)}
            />
          </InputContainer>
          <FormControls className='form-buttons'>
            <Button name="submit-button" type="submit" size='big' disabled={!isFormValid}>{t("chronos.auth.forgotPassword.restore")}</Button>
          </FormControls>
        </div>
      </Form>

      <ReCAPTCHA
          sitekey="6LdqFGUnAAAAAFPjZAmWlI1FS7TQNxF6vIsf5JKR"
          size="invisible"
          ref={captchaRef}
      />
    </PageContainer>
  )
});