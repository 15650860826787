export default [
  {
    name: "sun",
    title: "astro.planets.sun",
    speed: "chronos.auth.planets.sun.speed",
    archon: "chronos.auth.planets.sun.archon",
    sign: "chronos.auth.planets.sun.sign",
    img: "sun.jpg",
    desc: "chronos.auth.planets.sun.desc"
  },
  {
    name: "moon",
    title: "chronos.auth.planets.moon.title",
    speed: "chronos.auth.planets.moon.speed",
    archon: "chronos.auth.planets.moon.archon",
    sign: "chronos.auth.planets.moon.sign",
    img: "moon.jpg",
    desc: "chronos.auth.planets.moon.desc"
  },
  {
    name: "mercury",
    title: "chronos.auth.planets.mercury.title",
    speed: "chronos.auth.planets.mercury.speed",
    archon: "chronos.auth.planets.mercury.archon",
    sign: "chronos.auth.planets.mercury.sign",
    img: "mercury.jpg",
    desc: "chronos.auth.planets.mercury.desc"
  },
  {
    name: "venus",
    title: "chronos.auth.planets.venus.title",
    speed: "chronos.auth.planets.sun.speed",
    archon: "chronos.auth.planets.venus.archon",
    sign: "chronos.auth.planets.venus.sign",
    img: "venus.jpg",
    desc: "chronos.auth.planets.venus.desc"
  },
  {
    name: "mars",
    title: "astro.planets.mars",
    speed: "chronos.auth.planets.mars.speed",
    archon: "chronos.auth.planets.mars.archon",
    sign: "chronos.auth.planets.mars.sign",
    img: "mars.jpg",
    desc: "chronos.auth.planets.mars.desc"
  },
  {
    name: "jupiter",
    title: "chronos.auth.planets.jupiter.title",
    speed: "chronos.auth.planets.jupiter.speed",
    archon: "chronos.auth.planets.jupiter.archon",
    sign: "chronos.auth.planets.jupiter.sign",
    img: "jupiter.jpg",
    desc: "chronos.auth.planets.jupiter.desc"
  },
  {
    name: "saturn",
    title: "chronos.auth.planets.saturn.title",
    speed: "chronos.auth.planets.saturn.speed",
    archon: "chronos.auth.planets.saturn.archon",
    sign: "chronos.auth.planets.saturn.sign",
    img: "saturn.jpg",
    desc: "chronos.auth.planets.saturn.desc"
  },
  {
    name: "uranus",
    title: "chronos.auth.planets.uranus.title",
    speed: "chronos.auth.planets.uranus.speed",
    archon: "chronos.auth.planets.uranus.archon",
    sign: "chronos.auth.planets.uranus.sign",
    img: "uranus.jpg",
    desc: "chronos.auth.planets.uranus.desc"
  },
  {
    name: "neptune",
    title: "chronos.auth.planets.neptune.title",
    speed: "chronos.auth.planets.pluto.speed",
    archon: "chronos.auth.planets.neptune.archon",
    sign: "chronos.auth.planets.neptune.sign",
    img: "neptune.jpg",
    desc: "chronos.auth.planets.neptune.desc"
  },
  {
    name: "pluto",
    title:   "chronos.auth.planets.pluto.title",
    speed: "chronos.auth.planets.pluto.speed",
    archon: "chronos.auth.planets.pluto.archon",
    sign: "chronos.auth.planets.pluto.sign",
    img: "pluto.jpg",
    desc: "chronos.auth.planets.pluto.desc"
  }
]
