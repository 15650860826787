import React from 'react';
import styled from 'styled-components';

export const ButtonWithIcon = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  text-align: left;
  font-size: 1rem;
  font-weight: 500;

  @media (max-width: 600px) {
    font-size: 0.875rem;
  }
  
  svg {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
    margin-right: 0.688rem;
    flex-shrink: 0;
  }

  svg + * {
    display: inline-block;
    padding-left: 0.688rem;
    border-left: 1px solid var(--element-neutral);
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Form = styled.form``;

export const SocialButtons = styled.div`
  position: relative;
  margin-bottom: 1rem;
  
  & button {
    width: 100%;
  }
`;
export const FormControls = styled.div``;
